/*
 * Desactivació del formulari i els seus camps
 */
if ($('.disabledForm').length) {
	$(".disabledForm :input").prop("disabled", true).addClass('disabled');

}

// inicialitza select2
if ($(".chzn-select").length > 0) {
    $(".chzn-select").select2({
        width: "100%",
        allowClear: true,
        "language": {
            "noResults": function(){
                return "No se han encontrado resultados";
            }
        },
    });
}
if ($(".select2").length > 0) {
    $(".select2").select2({
        width: "100%",
        "language": {
            "noResults": function(){
                return "No se han encontrado resultados";
            }
        },

    });
}


/*
 * Automatismes en el formulari de donants de cordó
 */
if ($('.formCordo').length) {
	$("#estat_motiucancelacio").on('change', function(){
		var validate = $(this).closest('form').validate();
		validate.element('#estat_motiucancelacio');
	});

	$("#datacollecta").on('change', function(){
		if ($("#datanaixament").val() == '' && $(this).val() != '') {
			$("#datanaixament").val($(this).val());
		}
	});

	$("#resultatsprocesament_dataprocesament").on('change', function(){
		if ($("#resultatsprocesament_datacongelacio").val() == '' && $(this).val() != '') {
			$("#resultatsprocesament_datacongelacio").val($(this).val());
		}
	});

	$("#resultatsprocesament_datacongelacio").on('change', function(){
		if ($("#resultatsprocesament_dataprocesament").val() == '' && $(this).val() != '') {
			$("#resultatsprocesament_dataprocesament").val($(this).val());
		}
		if ($("#resultatsprocesament_dataviabilitat").val() == '' && $(this).val() != '') {
			$("#resultatsprocesament_dataviabilitat").val($(this).val());
		}
	});

	$("#resultatsprocesament_dataviabilitat").on('change', function(){
		if ($("#resultatsprocesament_datacongelacio").val() == '' && $(this).val() != '') {
			$("#resultatsprocesament_datacongelacio").val($(this).val());
		}
	});
}

/*
 * Doble confirmació dels camps de tipatge
 */
if ($("#tipatgeForm").length && $(".tipatgePart").length ) {

	// clonem el formulari de tipatge que cal confirmar per part de l'usuari
	var newFormTipatge= $(".tipatgePart").eq(0).clone();
	$('#tipatgeForm').append(newFormTipatge);
	$('#tipatgeForm').find('.tipatgePart').removeClass('tipatgePart');
	$('#tipatgeForm').find('input.validateTipatge').removeClass('validateTipatge');

	// afegim input de control dels valors de la part de tipatge del formulari
	$('<input type="hidden" id="validTipatgeCheck" name="validTipatge" value="" />').insertAfter(".tipatgePart");

	// actualitzem el valor serialitzat en cas de canvis
	$(".tipatgePart input").on('blur', function(){
		$("#validTipatgeCheck").val($(".tipatgePart :input").serialize());
	});
	// afegim norma per a la validació
	$("input#validTipatgeCheck").rules("add", "doubleCheckTipatge");

}

/*
 * Canvis de . per : pels camps de tipatge
 */
$("input.filtreTipatge").bind('keyup', function (e) {
    $(this).val(($(this).val()).replace('.', ':'));
});

/*
 * Telèfons
 * Aplicar atribut maxlenght a 9
 * Eliminació de ., espais i - per  pels camps de telefon
 * 10/01/2022 Treure maxlength per permetre telèfons extrangers (Andorra)
 */
// $("input.validateTelefon").attr('maxlength','10');
$("input.validateTelefon").bind('keyup', function (e) {
    $(this).val(($(this).val()).replace('.', '').replace('-', '').replace(' ', ''));
});


/*
 * Canvis de minúscula per majúscula en camps DNI
 */
$("input.dniDonant").bind('keyup', function (e) {
	$(this).val(($(this).val()).toUpperCase());
});

/*
 * actualitzar la validacio camps que necessiten provincia
 */
$("select.provincia-validate").bind('change', function (e) {
	var validate = $(this).closest('form').validate();
	validate.element('input.dniDonant');
	validate.element('input.validateCodiPostal');
	validate.element('input.validateTelefon');
});

/*
 * Botons de desat de formularis amb data attributes per loading mentre es valida el form
 */
$('.btn-loading-text').on('click', function() {
    var $this = $(this);
    $(this).html($(this).data('loading-text'));
    $(this).addClass('disabled');
});


$('a.checkAll').on('click', function(e){
	e.preventDefault();
	$(this).closest('form').find('input:checkbox').prop('checked', 'checked');
	return false;
});

$('a.unCheckAll').on('click', function(e){
	e.preventDefault();
	$(this).closest('form').find('input:checkbox').prop('checked', '');
	return false;
});


$(document).on('click', 'a.checkGroup', function(e){
	e.preventDefault();
	$(this).closest('fieldset').find('input:checkbox').prop('checked', 'checked');
	return false;
});

$(document).on('click', 'a.unCheckGroup', function(e){
	e.preventDefault();
	$(this).closest('fieldset').find('input:checkbox').prop('checked', '');
	return false;
});
