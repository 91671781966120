var idioma = $('html').attr('lang');
$('.houdiniForm').each(function() {//per cada formulari
    'use strict';
    //validacio formulari
    $(this).validate({
    	ignore: [],
    	onkeyup: false,
    	onclick: false,
    	onfocusout: function( element ) {
    	    if ( !this.checkable( element ) && ( element.name in this.submitted || !this.optional( element ) ) ) {
    	    	if (!$(element).hasClass('datepicker')) {
    	    		this.element( element );
    	    	}
    	    }
    	},
        errorPlacement: function(error, element) {
            if (element.attr('type') === 'checkbox' || element.attr('type') === 'radio') {
                if (element.closest('div').find('input').length > 1) {
                    error.insertAfter(element.closest('div').find('p.type-label'));
                } else {
                    error.insertAfter(element.parent('label'));
                }
            } else {
            	var errorShow = false;
            	if (element.hasClass('validatedatamotiususpensio')) {
            		$('.validatedatamotiususpensio').each(function(){
            			error.clone().insertAfter(this);
            		});
            		errorShow = true;
            	} 
            	if (element.hasClass('validatedatamotiucancelacio')) {
            		$('.validatedatamotiucancelacio').each(function()	{
            			error.clone().insertAfter(this);
            		});
            		errorShow = true;
            	} 
            	if (element.hasClass('validateTipatge') || element.hasClass('validateTipatgeMatern')) {
        			// no posem missatge d'error
            		errorShow = true;
            	} 
            	if (!errorShow){
            		error.insertAfter(element);
            	}
            }
        },
        highlight: function(element, errorClass, validClass) {
            $(element).addClass(errorClass).removeClass(validClass);
            if ($(element).parent().hasClass('select')) {
            	$(element).parent().addClass(errorClass);
            }
            if ($(element).closest('.select').length) {
            	$(element).closest('.select').addClass(errorClass);
            }
        	if ($(element).hasClass('validatedatamotiususpensio')) {
        		$('.validatedatamotiususpensio').addClass(errorClass);
        	}
        	if ($(element).hasClass('validatedatamotiucancelacio')) {
        		$('.validatedatamotiucancelacio').addClass(errorClass);
        	}
        },
        unhighlight: function(element, errorClass, validClass) {
        	$(element).removeClass(errorClass);
        	if ($(element).parent().hasClass('select')) {
            	$(element).parent().removeClass(errorClass);
            }
        	if ($(element).closest('.select').length) {
            	$(element).closest('.select').removeClass(errorClass);
            }
            if ($(element).hasClass('validatedatamotiususpensio')) {
        		$('.validatedatamotiususpensio').removeClass(errorClass);
        		$('.validatedatamotiususpensio').each(function(){
        			$(this).removeClass(errorClass);
        			$(this).parent().find('label.error').remove();
        		});
        	}
        	if ($(element).hasClass('validatedatamotiucancelacio')) {
        		$('.validatedatamotiucancelacio').removeClass(errorClass);
        		$('.validatedatamotiucancelacio').each(function(){
        			$(this).removeClass(errorClass);
        			$(this).parent().find('label.error').remove();
        		});
        	}
        },
        invalidHandler: function(event, validator) {
        	if ($(validator.currentForm).find('.btn-loading-text').length) {
        		var button = $(validator.currentForm).find('.btn-loading-text');
        		button.html(button.data('default'));	
        		button.removeClass('disabled');
        		button.removeProp('disabled');
        		button.removeAttr('disabled');
        		if ($('.alert-container').length) {
        			$('.alert-container').show();
        		}
        	}
        },
        errorContainer: $('div.alert-container'),
        


    });
});


//chosen
$('.houdiniForm .chzn-select').select2();
$('.multiple-select-help').hide(); //amaga misatge d'ajuda dels selects multiples


//select de dnis
$('.houdiniForm .dninifnie').each(function() {
    'use strict';
    $(this).find('select').on('change', function() {
        var value = $(this).val();
        var input = $(this).closest('div.row').find('input');

        if (value === 'DNI') {
            input.rules('add', {
                dni: true,
                cifES: false,
                nieES: false
            });
        } else if (value === 'CIF') {
            input.rules('add', {
                dni: false,
                cifES: true,
                nieES: false
            });
        } else if (value === 'NIE') {
            input.rules('add', {
                dni: false,
                cifES: false,
                nieES: true
            });
        }
    });
});
