jQuery.validator.addClassRules("validatedatamotiususpensio", {
	validatedatamotiususpensio: true
});

function hasInfoMotiuSuspensio(form)
{
	var dataSuspensio = $(form).find('input.validatedatamotiususpensio.datepicker:not(".optional")').val();
	var motiuSuspensio = $(form).find('select.validatedatamotiususpensio option:selected').val();
//	var dataReactivacio = $(form).find('input.validatedatamotiususpensio.datepicker.optional').val();
	var dataReactivacio = $(form).find('input#estat_datareactivacio').val();


	if (dataSuspensio !='' || motiuSuspensio !='' || dataReactivacio !='') {
		return true;
	}
	return false;
}

function hasInfoMotiuCancelacio(form)
{
	var dataCancelacio = $(form).find('input.validatedatamotiucancelacio.datepicker').val();
	var motiuCancelacio = $(form).find('select.validatedatamotiucancelacio option:selected').val();

	if (dataCancelacio !='' || motiuCancelacio !='') {
		return true;
	}
	return false;
}

jQuery.validator.addMethod('validatedatamotiususpensio', function(value, element) {


	var dataValue = $('input.validatedatamotiususpensio.datepicker:not(".optional")').val(),
	motiuValue = $('select.validatedatamotiususpensio option:selected').val();


	// si l'element no ha canviat respecte el valor original, passa la validació
	if ($('input.validatedatamotiususpensio.datepicker:not(".optional")').data('original') == $('input.validatedatamotiususpensio.datepicker:not(".optional")').val()
		&& $('select.validatedatamotiususpensio').data('original') == $('select.validatedatamotiususpensio').val()
		&& $('input.validatedatamotiususpensio.datepicker.optional').data('original') == $('input.validatedatamotiususpensio.datepicker.optional').val() ) {
		return true;
	}

	var isError = false;
	var errorMessage = 'Fecha y/o motivo de suspensión no válidos.';

	if (hasInfoMotiuSuspensio(element.form) && (dataValue == '' || motiuValue == '')) {
		isError = true;
		errorMessage = 'Debe rellenar los campos fecha y motivo de suspensión';
	}

	if (!isError) {
		if (hasInfoMotiuCancelacio(element.form) && hasInfoMotiuSuspensio(element.form) ) {
			isError = true;
			errorMessage = 'Informar únicamente datos relativos a la suspensión o la cancelación';
		}
	}
	if (!isError && hasInfoMotiuSuspensio(element.form)) {

		$('input.validatedatamotiususpensio.datepicker:not(".optional")').addClass('validating');
		$('select.validatedatamotiususpensio').addClass('validating');

	    var data = {data: dataValue, motiu: motiuValue};
	    $.ajax({
	        type: 'GET',
	        url: urlBase + '/validacions/ajax-validar-suspensio.php',
	        data: data,
	        dataType: "json",
	        async: false,
	        success: function(data, textStatus, jqXHR) {
	            if(data.isError == "true"){
	            	errorMessage = data.errorMessage;
	            	isError = true;
	            }
	        }
	    });

	    $('input.validatedatamotiususpensio.datepicker:not(".optional")').removeClass('validating');
		$('select.validatedatamotiususpensio').removeClass('validating');
	}




    if(!$(element).data('being_validated')) {
		    var fields = $('.validatedatamotiucancelacio, .validatedatamotiususpensio', element.form);
		    fields.data('being_validated', true);
		    fields.valid();
		    fields.data('being_validated', false);
    }

	if( isError == false) {
    	return true;
    } else {
    	jQuery.validator.messages.validatedatamotiususpensio = errorMessage;
    	return false;
    }
}, 'Fecha y/o motivo de suspensión no válidos.');


jQuery.validator.addClassRules("validatedatamotiucancelacio", {
	validatedatamotiucancelacio: true
});


jQuery.validator.addMethod('validatedatamotiucancelacio', function(value, element) {
	var dataValue = $('input.validatedatamotiucancelacio.datepicker').val(),
	motiuValue = $('select.validatedatamotiucancelacio option:selected').val();



	// si l'element no ha canviat respecte el valor original, passa la validació
	if ($('input.validatedatamotiucancelacio.datepicker').data('original') == $('input.validatedatamotiucancelacio.datepicker').val()
		&& $('select.validatedatamotiucancelacio').data('original') == $('select.validatedatamotiucancelacio').val() ) {
		return true;
	}


	var isError = false;
	var errorMessage = 'Fecha y/o motivo de cancelación no válidos.';

	if ((dataValue != '' && motiuValue == '') || (dataValue == '' && motiuValue != '')) {
		isError = true;
		errorMessage = 'Debe rellenar los campos fecha y motivo de cancelación';
	}

	if (!isError) {
		if (hasInfoMotiuCancelacio(element.form) && hasInfoMotiuSuspensio(element.form) ) {
			isError = true;
			errorMessage = 'Informar únicamente datos relativos a la suspensión o la cancelación';
		}
	}
	if (!isError && hasInfoMotiuCancelacio(element.form)) {
		$('input.validatedatamotiucancelacio.datepicker').addClass('validating');
		$('select.validatedatamotiucancelacio').addClass('validating');

	    var data = {data: dataValue, motiu: motiuValue};
	    $.ajax({
	        type: 'GET',
	        url: urlBase + '/validacions/ajax-validar-cancelacio.php',
	        data: data,
	        dataType: "json",
	        async: false,
	        success: function(data, textStatus, jqXHR) {
	            if(data.isError == "true"){
	            	errorMessage = data.errorMessage;
	            	isError = true;
	            }
	        }
	    });

	    $('input.validatedatamotiucancelacio.datepicker').removeClass('validating');
		$('select.validatedatamotiucancelacio').removeClass('validating');
	}


    if(!$(element).data('being_validated')) {
		    var fields = $('.validatedatamotiucancelacio, .validatedatamotiususpensio', element.form);
		    fields.data('being_validated', true);
		    fields.valid();
		    fields.data('being_validated', false);
    }


    if( isError == false) {
    	return true;
    } else {
    	jQuery.validator.messages.validatedatamotiucancelacio = errorMessage;
    	return false;
    }

}, 'Fecha y/o motivo de cancelación no válidos.');


jQuery.validator.addClassRules("dniDonant", {
	dniDonant: true
});


jQuery.validator.addMethod('dniDonant', function(value, element) {
	if ($("#idDonant").length) {
		var data = {nif: value, idDonant:$("#idDonant").text()};
	} else {
		var data = {nif: value};
	}

	// si l'element no ha canviat respecte el valor original, passa la validació
	if ($(element).data('original') == value) {
		return true;
	}

	if( this.optional(element) || value == '') {
    	return true;
    }

	var provincia = $('select.provincia-validate').val();
	if (provincia === '') {
		return true;
	}
	data.provincia = provincia;

	$(element).addClass('validating');
	var isError = false;
	var errorMessage = 'Por favor, escribe un NIF válido.';
    result = $.ajax({
        type: 'GET',
        url: urlBase + '/validacions/ajax-validar-nif.php',
        data: data,
        dataType: "json",
        async: false,
        success: function(data, textStatus, jqXHR) {
            if(data.isError == "true"){
            	errorMessage = data.errorMessage;
            	isError = true;
            }
        }
    });
    $(element).removeClass('validating');
    if( this.optional(element) || isError == false) {
    	return true;
    } else {
    	jQuery.validator.messages.dniDonant = errorMessage;
    	return false;
    }

}, 'Por favor, escribe un NIF válido.');

jQuery.validator.addClassRules("validateCodiPostal", {
//	digits: true,
	exactlength: 5,
	codiPostalMethod: true
});


jQuery.validator.addMethod('codiPostalMethod', function(value, element) {
	var data = {cp: value};

	// si l'element no ha canviat respecte el valor original, passa la validació
	if ($(element).data('original') == value) {
		return true;
	}

	if( this.optional(element) || value == '') {
    	return true;
    }

	var provincia = $('select.provincia-validate').val();
	if (provincia === '') {
		return true;
	}
	data.provincia = provincia;

	$(element).addClass('validating');
	var isError = false;
	var errorMessage = 'Por favor, escribe un CP válido.';
    result = $.ajax({
        type: 'GET',
        url: urlBase + '/validacions/ajax-validar-cp.php',
        data: data,
        dataType: "json",
        async: false,
        success: function(data, textStatus, jqXHR) {
            if(data.isError == "true"){
            	errorMessage = data.errorMessage;
            	isError = true;
            }
        }
    });
    $(element).removeClass('validating');
    if( this.optional(element) || isError == false) {
    	return true;
    } else {
    	jQuery.validator.messages.codiPostal = errorMessage;
    	return false;
    }

}, 'Por favor, escribe un CP válido.');




jQuery.validator.addClassRules("datepicker", {
	datepicker: true
});


jQuery.validator.addMethod('datepicker', function(value, element) {

	// si l'element no ha canviat respecte el valor original, passa la validació
	if ($(element).data('original') == value) {
		return true;
	}

	if( this.optional(element) || value == '') {
    	return true;
    }
	isError = true;
	if (value.match(/^(0[1-9]|[12][0-9]|3[01])(\/)(?:(0[1-9]|1[012])(\/)\d{4})$/)) {
		isError = false;
	}

    if( this.optional(element) || isError == false) {
    	return true;
    } else {
    	return false;
    }

}, 'Por favor, escribe una fecha con formato dd/mm/yyyy.');


jQuery.validator.addClassRules("validateEdat", {
	edat: true
});


jQuery.validator.addMethod('edat', function(value, element) {
	var data = {edat: value};

	// si l'element no ha canviat respecte el valor original, passa la validació
	if ($(element).data('original') == value) {
		return true;
	}

	if( this.optional(element) || value == '') {
    	return true;
    }

	$(element).addClass('validating');
	var isError = false;
	var errorMessage = 'Por favor, escribe una fecha de nacimiento válida.';
    result = $.ajax({
        type: 'GET',
        url: urlBase + '/validacions/ajax-validar-edat.php',
        data: data,
        dataType: "json",
        async: false,
        success: function(data, textStatus, jqXHR) {
            if(data.isError == "true"){
            	errorMessage = data.errorMessage;
            	isError = true;
            }
        }
    });
    $(element).removeClass('validating');
    if( this.optional(element) || isError == false) {
    	return true;
    } else {
    	jQuery.validator.messages.edat = errorMessage;
    	return false;
    }

}, 'Por favor, escribe una fecha de nacimiento válida.');

jQuery.validator.addClassRules("validateEmail", {
	email: true,
	validateEmailMethod: true
});


jQuery.validator.addMethod('validateEmailMethod', function(value, element) {
	var data = {email: value};

	// si l'element no ha canviat respecte el valor original, passa la validació
	if ($(element).data('original') == value) {
		return true;
	}

	return this.optional( element ) || /^[a-zA-Z0-9][\w\.-]*@[a-zA-Z0-9][\w\.-]*\.[a-zA-Z0-9][a-zA-Z\.]*[a-zA-Z0-9]$/.test(value);

}, 'Por favor, escribe un email válido.');


/*
jQuery.validator.addMethod('validateEmailMethodEPICDP', function(value, element) {
	var data = {email: value};

	// si l'element no ha canviat respecte el valor original, passa la validació
	if ($(element).data('original') == value) {
		return true;
	}

	if( this.optional(element) || value == '') {
    	return true;
    }

	$(element).addClass('validating');
	var isError = false;
	var errorMessage = 'Por favor, escribe un email válido.';
    result = $.ajax({
        type: 'GET',
        url: urlBase + '/validacions/ajax-validar-email.php',
        data: data,
        dataType: "json",
        async: false,
        success: function(data, textStatus, jqXHR) {
            if(data.isError == "true"){
            	errorMessage = data.errorMessage;
            	isError = true;
            }
        }
    });
    $(element).removeClass('validating');
    if( this.optional(element) || isError == false) {
    	return true;
    } else {
    	jQuery.validator.messages.validateEmail = errorMessage;
    	return false;
    }

}, 'Por favor, escribe un email válido.');
*/

jQuery.validator.addClassRules("validateTelefon", {
//	digits: true,
	//exactlength: 9,
	validateClientTelefonMethod: true,
	validateTelefonMethod: true
});

jQuery.validator.addMethod('validateClientTelefonMethod', function(value, element) {
	// si l'element no ha canviat respecte el valor original, passa la validació
	if ($(element).data('original') == value) {
		return true;
	}

	if( this.optional(element) || value == '') {
    	return true;
    }

	return this.optional( element ) || /^(\+)?[0-9]+$/.test( value );

}, 'Por favor, escribe un teléfono válido.');


jQuery.validator.addMethod('validateTelefonMethod', function(value, element) {
	var data = {telefon: value};

	// si l'element no ha canviat respecte el valor original, passa la validació
	if ($(element).data('original') == value) {
		return true;
	}

	if( this.optional(element) || value == '') {
    	return true;
    }

	var provincia = $('select.provincia-validate').val();
	if (provincia === '') {
		return true;
	}
	data.provincia = provincia;

	$(element).addClass('validating');

	var isError = false;
	var errorMessage = 'Por favor, escribe un teléfono válido.';
    result = $.ajax({
        type: 'GET',
        url: urlBase + '/validacions/ajax-validar-telefon.php',
        data: data,
        dataType: "json",
        async: false,
        success: function(data, textStatus, jqXHR) {
            if(data.isError == "true"){
            	errorMessage = data.errorMessage;
            	isError = true;
            }
        }
    });
    $(element).removeClass('validating');
    if( this.optional(element) || isError == false) {
    	return true;
    } else {
    	jQuery.validator.messages.validateTelefon = errorMessage;
    	return false;
    }

}, 'Por favor, escribe un teléfono válido.');



jQuery.validator.addClassRules("validatecoincidencies", {
	validatecoincidencies: true
});


jQuery.validator.addMethod('validatecoincidencies', function(value, element) {
	var nomValue = $('input.coincidencies.nom').val(),
	cognom1Value = $('input.coincidencies.cognom1').val(),
	cognom2Value = $('input.coincidencies.cognom2').val();

	// si l'element no ha canviat respecte el valor original, passa la validació
	if ($('input.coincidencies.nom').data('original') == $('input.coincidencies.nom').val()
		&& $('input.coincidencies.cognom1').data('original') == $('input.coincidencies.cognom1').val()
		&& $('input.coincidencies.cognom2').data('original') == $('input.coincidencies.cognom2').val() ) {
		return true;
	}

	if (cognom1Value == '' || cognom2Value == '' || nomValue == '' ) {
		return true;
	}

	$('.coincidencies').addClass('validating');
	var hasConincidences = false;
	var coincidenciesHtml = '';

    if ($("#idDonant").length) {
		var data = {nom: nomValue, cognom1: cognom1Value, cognom2: cognom2Value, idDonant:$("#idDonant").text()};
	} else {
		var data = {nom: nomValue, cognom1: cognom1Value, cognom2: cognom2Value};
	}

    $.ajax({
        type: 'GET',
        url: urlBase + '/validacions/ajax-validar-coincidencies.php',
        data: data,
        dataType: "json",
        async: false,
        success: function(data, textStatus, jqXHR) {
            if(data.hasCoincidencies == "true"){
            	coincidenciesHtml = data.coincidenciesHtml;
            	hasConincidences = true;
            }
        }
    });

    $(element).closest('.row-narrow').find('.coincidences-info').remove();
    if (hasConincidences) {
    	$(element).closest('.row-narrow').append('<div class="col-xs-24 coincidences-info"><div class="alert alert-info"> <p>Existen coincidencias con otros donantes: </p> ' +  coincidenciesHtml + ' </div></div>')
    }

    $('.coincidencies').removeClass('validating');

    return true;

}, '');


jQuery.validator.addMethod('doubleCheckTipatge', function(value, element) {

	if ($(".tipatgePart :input").serialize() != $("#tipatgeForm :input").serialize()) {
		$('#myTipatgeModal input[type="text"]').removeClass('needAtenttion');
		$('#myTipatgeModal input[type="text"]').each(function(){
			if ($(this).val() != $('.tipatgePart input[name="' + $(this).attr('name') + '"]').val()) {
				$(this).addClass('needAtenttion');
			}
			//$(document).on('change keyup input propertychange paste autocompleteselect', '#myTipatgeModal input[name="' + $(this).attr('name') + '"]', function(){
			$(document).on('input propertychange paste autocompleteselect', '#myTipatgeModal input[name="' + $(this).attr('name') + '"]', function(){
				if ($(this).val() == $('.tipatgePart input[name="' + $(this).attr('name') + '"]').val()) {
					$(this).removeClass('needAtenttion');
				} else {
					$(this).addClass('needAtenttion');
				}

				if ($('#myTipatgeModal input.needAtenttion').length == 0) {
//					$('#myTipatgeModal .btn-confirm').removeClass('disabled');
//					$('#myTipatgeModal .btn-confirm').removeProp('disabled');
//					$('#myTipatgeModal .btn-confirm').removeAttr('disabled');

					var validator = $(element).closest('form').data('validator');
					validator.resetForm();

				} else {
//					$('#myTipatgeModal .btn-confirm').addClass('disabled');
//					$('#myTipatgeModal .btn-confirm').prop('disabled', 'disabled');

				}
			})
		});

//		$('#myTipatgeModal .btn-confirm').addClass('disabled');
//		$('#myTipatgeModal .btn-confirm').prop('disabled', 'disabled');
		$('#myTipatgeModal').modal('show');

		$(document).on('click', '#myTipatgeModal button.btn-confirm', function (event) {
			if ($(element).closest('form').find('.btn-loading-text').length) {
				$(element).closest('form').find('.btn-loading-text').trigger('click');
			} else {
				$(element).closest('form').submit();
			}
		})

		return false;
	}

	return true;


}, '');




jQuery.validator.addClassRules("validateTipatge", {
	validateTipatge: true
});


jQuery.validator.addMethod('validateTipatge', function(value, element) {
	var matches = $(element).attr('name').match(/tipatge\[(\w+)\]/);

	// si l'element no ha canviat respecte el valor original, passa la validació
	if ($(element).data('original') == value) {
		return true;
	}

	if ($(element).data('validated') == value) {
		return true;
	}

	if( this.optional(element) || value == '') {
    	return true;
    }
	var data = {variable : matches[1], valor : value};
	$(element).addClass('validating');
	var isError = false;
	var errorMessage = 'Por favor, escribe un tipaje válido.';
    $.ajax({
        type: 'GET',
        mode: "abort",
        url: urlBase + '/validacions/ajax-validar-tipatge.php',
        data: data,
        dataType: "json",
        async: false,
        success: function(data, textStatus, jqXHR) {
            if(data.isError == "true"){
            	errorMessage = data.errorMessage;
            	isError = true;
            }
        }
//    }).done(function(data) {
//    	console.log('done!');
//    	console.log(data);
//    	console.log('done!!');
//    	$(element).removeClass('validating');
//    	if( isError == false) {
//    		return true;
//		} else {
//			jQuery.validator.messages.validateTipatge = errorMessage;
//			return false;
//		}
    });

    $(element).removeClass('validating');
    if( this.optional(element) || isError == false) {
    	$(element).attr('data-validated', value);
    	return true;
    } else {
    	jQuery.validator.messages.validateTipatge = errorMessage;
    	return false;
    }

}, 'Por favor, escribe un tipaje válido.');

jQuery.validator.addClassRules("validateTipatgeMatern", {
	validateTipatgeMatern: true
});


jQuery.validator.addMethod('validateTipatgeMatern', function(value, element) {
	var matches = $(element).attr('name').match(/marcadorsserologics\[(\w+)\]/);

	// si l'element no ha canviat respecte el valor original, passa la validació
	if ($(element).data('original') == value) {
		return true;
	}

	if ($(element).data('validated') == value) {
		return true;
	}

	if( this.optional(element) || value == '') {
    	return true;
    }
	var data = {variable : matches[1], valor : value};
	$(element).addClass('validating');
	var isError = false;
	var errorMessage = 'Por favor, escribe un tipaje válido.';
    $.ajax({
        type: 'GET',
        mode: "abort",
        url: urlBase + '/validacions/ajax-validar-tipatge-matern.php',
        data: data,
        dataType: "json",
        async: false,
        success: function(data, textStatus, jqXHR) {
            if(data.isError == "true"){
            	errorMessage = data.errorMessage;
            	isError = true;
            }
        }
//    }).done(function(data) {
//    	console.log('done!');
//    	console.log(data);
//    	console.log('done!!');
//    	$(element).removeClass('validating');
//    	if( isError == false) {
//    		return true;
//		} else {
//			jQuery.validator.messages.validateTipatge = errorMessage;
//			return false;
//		}
    });

    $(element).removeClass('validating');
    if( this.optional(element) || isError == false) {
    	$(element).attr('data-validated', value);
    	return true;
    } else {
    	jQuery.validator.messages.validateTipatge = errorMessage;
    	return false;
    }

}, 'Por favor, escribe un tipaje válido.');


jQuery.validator.addClassRules("validateCordoVolum", {
	validateCordoVolum: true
});


jQuery.validator.addMethod('validateCordoVolum', function(value, element) {


	var $fields = $( ".validateCordoVolum", element.form ),
	$fieldsFirst = $fields.eq( 0 ),
	validator = $fieldsFirst.data( "valid_req_grp" ) ? $fieldsFirst.data( "valid_req_grp" ) : $.extend( {}, this );
	var isValid = true;


	var empty = true;
	$('.validateCordoVolum').each (function(){
		if ($(this).val() != '' && empty) {
			empty = false;
		}
	});

	if (empty) {
		isValid = false;
	}




	// Store the cloned validator for future validation
	$fieldsFirst.data( "valid_req_grp", validator );

	// If element isn't being validated, run each require_from_group field's validation rules
	if ( !$( element ).data( "being_validated" ) ) {
		$fields.data( "being_validated", true );
		$fields.each( function() {
			validator.element( this );
		} );
		$fields.data( "being_validated", false );
	}




	return isValid;

}, 'Por favor,  Volumen recogido o 	Volumen congelado deben estar llenos');





jQuery.validator.addClassRules("validatePastDate", {
	validatePastDate: true
});


jQuery.validator.addMethod('validatePastDate', function(value, element) {

	if( this.optional(element) || value == '') {
    	return true;
    }
	var parts = value.split("/");
    var date = new Date(parts[2], parts[1] - 1, parts[0]);
    var today = new Date();

    return (date <= today);
}, 'Por favor,  introduce una fecha anterior o igual al día de hoy');


jQuery.validator.addClassRules("validateDateBiggerThan19980101", {
	validateDateBiggerThan19980101: true
});


jQuery.validator.addMethod('validateDateBiggerThan19980101', function(value, element) {

	if( this.optional(element) || value == '') {
    	return true;
    }
	var parts = value.split("/");
    var date = new Date(parts[2], parts[1] - 1, parts[0]);
    var maxDate = new Date(1998,0,1);

    return (date >= maxDate);
}, 'Por favor,  introduce una fecha mayor o igual a 01/01/1998');



jQuery.validator.addClassRules("validateDateRange48", {
	validateDateRange48: true
});


jQuery.validator.addMethod('validateDateRange48', function(value, element) {


	var $fields = $( ".validateDateRange48", element.form ),
	$fieldsFirst = $fields.eq( 0 ),
	validator = $fieldsFirst.data( "valid_req_grp" ) ? $fieldsFirst.data( "valid_req_grp" ) : $.extend( {}, this );
	var isValid = true;

	var empty = true;
	var val1 = $('.validateDateRange48.validateDateRange48_order1').val();
	var val2 = $('.validateDateRange48.validateDateRange48_order2').val();
	var val3 = $('.validateDateRange48.validateDateRange48_order3').val();
	var val4 = $('.validateDateRange48.validateDateRange48_order4').val();

	var dates = Array();
	if (val1 !== '') {
		var parts = val1.split("/");
		dates.push(new Date(parts[2], parts[1] - 1, parts[0], 2, 0));
	}
	if (val2 !== '') {
		var parts = val2.split("/");
		dates.push(new Date(parts[2], parts[1] - 1, parts[0], 2, 0));
	}
	if (val3 !== '') {
		var parts = val3.split("/");
		dates.push(new Date(parts[2], parts[1] - 1, parts[0], 2, 0));
	}
	if (val4 !== '') {
		var parts = val4.split("/");
		dates.push(new Date(parts[2], parts[1] - 1, parts[0], 2, 0));
	}

	var firstDate = '';
	var maxDate = '';
	var prevDate = '';
	for (var i = 0; i < dates.length; i++) {
	    if (i == 0) {
	    	firstDate = dates[i];
	    	maxDate = new Date(firstDate);
	    	maxDate.setDate(maxDate.getDate() + 2); // 48 hores = 2 dies

	    	prevDate = new Date(firstDate);
	    } else {
	    	if (!(firstDate <= prevDate && prevDate <= dates[i] && dates[i] <= maxDate)) {
	    		isValid = false;
	    	}
	    	prevDate = new Date(dates[i].valueOf());
	    }
	}




	// Store the cloned validator for future validation
	$fieldsFirst.data( "valid_req_grp", validator );

	// If element isn't being validated, run each require_from_group field's validation rules
	if ( !$( element ).data( "being_validated" ) ) {
		$fields.data( "being_validated", true );
		$fields.each( function() {
			validator.element( this );
		} );
		$fields.data( "being_validated", false );
	}



	return isValid;

}, 'Por favor, revisa la coherencia de las siguientes fechas: Nacimiento, Colecta, Procesamiento y Congelación. No puede haber más de 48 horas de diferencia entre la primera y la última');





jQuery.validator.addClassRules("validateIdBossaNumBosses", {
	validateIdBossaNumBosses: true
});


jQuery.validator.addMethod('validateIdBossaNumBosses', function(value, element) {

//	var $fields = $( options[ 1 ], element.form ),
//	$fieldsFirst = $fields.eq( 0 ),
//	validator = $fieldsFirst.data( "valid_req_grp" ) ? $fieldsFirst.data( "valid_req_grp" ) : $.extend( {}, this ),
//	isValid = $fields.filter( function() {
//		return validator.elementValue( this );
//	} ).length >= options[ 0 ];
//
//	// Store the cloned validator for future validation
//	$fieldsFirst.data( "valid_req_grp", validator );
//
//	// If element isn't being validated, run each require_from_group field's validation rules
//	if ( !$( element ).data( "being_validated" ) ) {
//		$fields.data( "being_validated", true );
//		$fields.each( function() {
//			validator.element( this );
//		} );
//		$fields.data( "being_validated", false );
//	}
//	return isValid;




	var $fields = $( ".validateIdBossaNumBosses", element.form ),
	$fieldsFirst = $fields.eq( 0 ),
	validator = $fieldsFirst.data( "valid_req_grp" ) ? $fieldsFirst.data( "valid_req_grp" ) : $.extend( {}, this );


	var idBossa = $('input.validateIdBossaNumBosses[type="text"]').val();
	var numBosses = $('input.validateIdBossaNumBosses[type="number"]').val();


	var isValid = true;
	if ((numBosses == '' || numBosses > 1) && idBossa != '') {
		isValid = false;
	}

	// Store the cloned validator for future validation
	$fieldsFirst.data( "valid_req_grp", validator );

	// If element isn't being validated, run each require_from_group field's validation rules
	if ( !$( element ).data( "being_validated" ) ) {
		$fields.data( "being_validated", true );
		$fields.each( function() {
			validator.element( this );
		} );
		$fields.data( "being_validated", false );
	}

	return isValid;

}, 'ID bolsa solo aceptable para unidades congeladas en una sola bolsa');

function validateViabilitatFields(cssClassSelector, element, jqueryValidator) {
	
	var $fields = $( cssClassSelector, element.form ),
	$fieldsFirst = $fields.eq( 0 ),
	validator = $fieldsFirst.data( "valid_req_grp" ) ? $fieldsFirst.data( "valid_req_grp" ) : jqueryValidator;
	var isValid = true;
	
	var data = $('input' + cssClassSelector + '[type="text"]').val();
	var viabilitat = $('input' + cssClassSelector + '[type="number"]').val();

	if ((data == '' && viabilitat == '') || (data != '' && viabilitat != '' )) {
		isValid = true;
	} else {
		isValid = false;
	}

	// Store the cloned validator for future validation
	$fieldsFirst.data( "valid_req_grp", validator );

	// If element isn't being validated, run each require_from_group field's validation rules
	if ( !$( element ).data( "being_validated" ) ) {
		$fields.data( "being_validated", true );
		$fields.each( function() {
			validator.element( this );
		} );
		$fields.data( "being_validated", false );
	}

	return isValid;
	
}

jQuery.validator.addClassRules("validateDataViabilitatiViabilitatCNT", {
	validateDataViabilitatiViabilitatCNT: true
});

jQuery.validator.addMethod('validateDataViabilitatiViabilitatCNT', function(value, element) {
	return validateViabilitatFields('.validateDataViabilitatiViabilitatCNT', element, $.extend( {}, this ));
}, 'Viabilidad y Fecha de viabilidad deben estar los dos llenos o los dos vacios');

jQuery.validator.addClassRules("validateDataViabilitatiViabilitatCD34", {
	validateDataViabilitatiViabilitatCD34: true
});

jQuery.validator.addMethod('validateDataViabilitatiViabilitatCD34', function(value, element) {
	return validateViabilitatFields('.validateDataViabilitatiViabilitatCD34', element, $.extend( {}, this ));
}, 'Viabilidad y Fecha de viabilidad deben estar los dos llenos o los dos vacios');

jQuery.validator.addClassRules("validateDataViabilitatiViabilitatCD45", {
	validateDataViabilitatiViabilitatCD45: true
});

jQuery.validator.addMethod('validateDataViabilitatiViabilitatCD45', function(value, element) {
	return validateViabilitatFields('.validateDataViabilitatiViabilitatCD45', element, $.extend( {}, this ));
}, 'Viabilidad y Fecha de viabilidad deben estar los dos llenos o los dos vacios');

jQuery.validator.addClassRules("validateDataViabilitatiViabilitatPTTNC", {
	validateDataViabilitatiViabilitatPTTNC: true
});

jQuery.validator.addMethod('validateDataViabilitatiViabilitatPTTNC', function(value, element) {
	return validateViabilitatFields('.validateDataViabilitatiViabilitatPTTNC', element, $.extend( {}, this ));
}, 'Viabilidad y Fecha de viabilidad deben estar los dos llenos o los dos vacios');

jQuery.validator.addClassRules("validateDataViabilitatiViabilitatPTCD34", {
	validateDataViabilitatiViabilitatPTCD34: true
});

jQuery.validator.addMethod('validateDataViabilitatiViabilitatPTCD34', function(value, element) {
	return validateViabilitatFields('.validateDataViabilitatiViabilitatPTCD34', element, $.extend( {}, this ));
}, 'Viabilidad y Fecha de viabilidad deben estar los dos llenos o los dos vacios');



jQuery.validator.addClassRules("validateDataTipatgeConfirmatoriiTipusMostra", {
	validateDataTipatgeConfirmatoriiTipusMostra: true
});


jQuery.validator.addMethod('validateDataTipatgeConfirmatoriiTipusMostra', function(value, element) {


	var $fields = $( ".validateDataTipatgeConfirmatoriiTipusMostra", element.form ),
	$fieldsFirst = $fields.eq( 0 ),
	validator = $fieldsFirst.data( "valid_req_grp" ) ? $fieldsFirst.data( "valid_req_grp" ) : $.extend( {}, this );
	var isValid = true;


	var data = $('input.validateDataTipatgeConfirmatoriiTipusMostra[type="text"]').val();
	var tipus = $('select.validateDataTipatgeConfirmatoriiTipusMostra').val();


	if ((data == '' && tipus == '') || (data != '' && tipus != '' )) {
		isValid = true;
	} else {
		isValid = false;
	}




	// Store the cloned validator for future validation
	$fieldsFirst.data( "valid_req_grp", validator );

	// If element isn't being validated, run each require_from_group field's validation rules
	if ( !$( element ).data( "being_validated" ) ) {
		$fields.data( "being_validated", true );
		$fields.each( function() {
			validator.element( this );
		} );
		$fields.data( "being_validated", false );
	}




	return isValid;

}, 'Fecha tipaje confirmatorio y Tipo de muestra para CT deben estar los dos llenos o los dos vacios');





/*
jQuery.validator.addClassRules("validateDataNaixementiDataViabilitat", {
	validateDataNaixementiDataViabilitat: true
});


jQuery.validator.addMethod('validateDataNaixementiDataViabilitat', function(value, element) {


	var $fields = $( ".validateDataNaixementiDataViabilitat", element.form ),
	$fieldsFirst = $fields.eq( 0 ),
	validator = $fieldsFirst.data( "valid_req_grp" ) ? $fieldsFirst.data( "valid_req_grp" ) : $.extend( {}, this );
	var isValid = true;


	var val1 = $('.validateDataNaixementiDataViabilitat#datanaixament').val();
	var val2 = $('.validateDataNaixementiDataViabilitat#resultatsprocesament_dataviabilitat').val();


	if (val1 != '' && val2 != '') {
		var parts = val1.split("/");
		var data1 = new Date(parts[2], parts[1] - 1, parts[0], 2, 0);
		var parts = val2.split("/");
		var data2 = new Date(parts[2], parts[1] - 1, parts[0], 2, 0);

		isValid = (data2 >= data1);
	}


	// Store the cloned validator for future validation
	$fieldsFirst.data( "valid_req_grp", validator );

	// If element isn't being validated, run each require_from_group field's validation rules
	if ( !$( element ).data( "being_validated" ) ) {
		$fields.data( "being_validated", true );
		$fields.each( function() {
			validator.element( this );
		} );
		$fields.data( "being_validated", false );
	}




	return isValid;

}, 'La fecha de viabilidad debe ser mayor que la fecha de nacimiento');
*/

function validateDataNaixementiDataViabilitatFields(cssClassSelector, idViabilitatDateField, element, jqueryValidator) {

	var $fields = $( cssClassSelector, element.form ),
	$fieldsFirst = $fields.eq( 0 ),
	validator = $fieldsFirst.data( "valid_req_grp" ) ? $fieldsFirst.data( "valid_req_grp" ) : jqueryValidator;
	var isValid = true;

	var val1 = $(cssClassSelector + '#datanaixament').val();
	var val2 = $(cssClassSelector + '#' + idViabilitatDateField).val();

	if (val1 != '' && val2 != '') {
		var parts = val1.split("/");
		var data1 = new Date(parts[2], parts[1] - 1, parts[0], 2, 0);
		var parts = val2.split("/");
		var data2 = new Date(parts[2], parts[1] - 1, parts[0], 2, 0);

		isValid = (data2 >= data1);
	}

	// Store the cloned validator for future validation
	$fieldsFirst.data( "valid_req_grp", validator );

	// If element isn't being validated, run each require_from_group field's validation rules
	if ( !$( element ).data( "being_validated" ) ) {
		$fields.data( "being_validated", true );
		$fields.each( function() {
			validator.element( this );
		} );
		$fields.data( "being_validated", false );
	}

	return isValid;	
}

jQuery.validator.addClassRules("validateDataNaixementiDataViabilitatCNT", {
	validateDataNaixementiDataViabilitatCNT: true
});

jQuery.validator.addMethod('validateDataNaixementiDataViabilitatCNT', function(value, element) {
	return validateDataNaixementiDataViabilitatFields('.validateDataNaixementiDataViabilitatCNT', 'resultatspc_dataviabilitat', element, $.extend( {}, this ));
}, 'La fecha de viabilidad debe ser mayor que la fecha de nacimiento');

jQuery.validator.addClassRules("validateDataNaixementiDataViabilitatCD34", {
	validateDataNaixementiDataViabilitatCD34: true
});

jQuery.validator.addMethod('validateDataNaixementiDataViabilitatCD34', function(value, element) {
	return validateDataNaixementiDataViabilitatFields('.validateDataNaixementiDataViabilitatCD34', 'resultatspc_cd34pcviabilitydate', element, $.extend( {}, this ));
}, 'La fecha de viabilidad debe ser mayor que la fecha de nacimiento');

jQuery.validator.addClassRules("validateDataNaixementiDataViabilitatCD45", {
	validateDataNaixementiDataViabilitatCD45: true
});

jQuery.validator.addMethod('validateDataNaixementiDataViabilitatCD45', function(value, element) {
	return validateDataNaixementiDataViabilitatFields('.validateDataNaixementiDataViabilitatCD45', 'resultatspc_cd45pcviabilitydate', element, $.extend( {}, this ));
}, 'La fecha de viabilidad debe ser mayor que la fecha de nacimiento');


jQuery.validator.addClassRules("validateDataNaixementiDataViabilitatPTTNC", {
	validateDataNaixementiDataViabilitatPTTNC: true
});

jQuery.validator.addMethod('validateDataNaixementiDataViabilitatPTTNC', function(value, element) {
	return validateDataNaixementiDataViabilitatFields('.validateDataNaixementiDataViabilitatPTTNC', 'resultatspt_viabilitytncptdate', element, $.extend( {}, this ));
}, 'La fecha de viabilidad debe ser mayor que la fecha de nacimiento');

jQuery.validator.addClassRules("validateDataNaixementiDataViabilitatPTCD34", {
	validateDataNaixementiDataViabilitatPTCD34: true
});

jQuery.validator.addMethod('validateDataNaixementiDataViabilitatPTCD34', function(value, element) {
	return validateDataNaixementiDataViabilitatFields('.validateDataNaixementiDataViabilitatPTCD34', 'resultatspt_viabilitycd34pcptdate', element, $.extend( {}, this ));
}, 'La fecha de viabilidad debe ser mayor que la fecha de nacimiento');



/*
 * Lets you say "either at least X inputs that match selector Y must be filled,
 * OR they must all be skipped (left blank)."
 *
 * The end result, is that none of these inputs:
 *
 *	<input class="productinfo" name="partnumber">
 *	<input class="productinfo" name="description">
 *	<input class="productinfo" name="color">
 *
 *	...will validate unless either at least two of them are filled,
 *	OR none of them are.
 *
 * partnumber:	{skip_or_fill_minimum: [2,".productinfo"]},
 * description: {skip_or_fill_minimum: [2,".productinfo"]},
 * color:		{skip_or_fill_minimum: [2,".productinfo"]}
 *
 * options[0]: number of fields that must be filled in the group
 * options[1]: CSS selector that defines the group of conditionally required fields
 *
 */
jQuery.validator.addMethod( "skip_or_fill_minimum", function( value, element, options ) {
	var $fields = $( options[ 1 ], element.form ),
		$fieldsFirst = $fields.eq( 0 ),
		validator = $fieldsFirst.data( "valid_skip" ) ? $fieldsFirst.data( "valid_skip" ) : $.extend( {}, this ),
		numberFilled = $fields.filter( function() {
			return validator.elementValue( this );
		} ).length,
		isValid = numberFilled === 0 || numberFilled >= options[ 0 ];

	// Store the cloned validator for future validation
	$fieldsFirst.data( "valid_skip", validator );

	// If element isn't being validated, run each skip_or_fill_minimum field's validation rules
	if ( !$( element ).data( "being_validated" ) ) {
		$fields.data( "being_validated", true );
		$fields.each( function() {
			validator.element( this );
		} );
		$fields.data( "being_validated", false );
	}
	return isValid;
}, $.validator.format( "Please either skip these fields or fill at least {0} of them." ) );