vex.defaultOptions.className = 'vex-theme-default';


Date.prototype.yyyymmdd = function() {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [this.getFullYear(),
          (mm>9 ? '' : '0') + mm,
          (dd>9 ? '' : '0') + dd
         ].join('');
};

Date.prototype.ddmmyyyy = function() {
	  var mm = this.getMonth() + 1; // getMonth() is zero-based
	  var dd = this.getDate();

	  return [
		  	  (dd>9 ? '' : '0') + dd,
	          (mm>9 ? '' : '0') + mm,
	          this.getFullYear()
	         ].join('/');
	};

$(document).ready(function() {
    'use strict';

    // feature.js
    if (feature.svg) {
        $('html').addClass('svg');
    }

    // datepicker
    var datepicker = $.fn.datepicker.noConflict(); // return $.fn.datepicker to previously assigned value
    $.fn.bootstrapDP = datepicker;
    $('.datepicker').bootstrapDP({
    	orientation: "bottom left",
        language: 'es',
        autoclose: true,
        todayHighlight: true,
        weekStart: 1,
        format: "dd/mm/yyyy",
        todayBtn: 'linked'

    }).on('changeDate', function (e) {
		$(this).valid();
	}).on('change', function(e){
		$(this).valid();
	});

    $('.datepicker.pastDate').bootstrapDP('setEndDate', '0d');
    $('.datepicker.dateBiggerThan19980101').bootstrapDP('setStartDate', '01/01/1998');

    $('.datepicker').keydown(function( event ) {
    	if ( event.key == 'h' || event.key == 'H' ) {
    		var date = new Date();
    		$(this).val(date.ddmmyyyy());
    	}
    });


    // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
    // bind a click event to the 'skip' link
    $(".skip-link").click(function(event){

        // strip the leading hash and declare
        // the content we're skipping to
        var skipTo="#"+this.href.split('#')[1];

        // Setting 'tabindex' to -1 takes an element out of normal
        // tab flow but allows it to be focused via javascript
        $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

            // when focus leaves this element,
            // remove the tabindex attribute
            $(this).removeAttr('tabindex');

        }).focus(); // focus on the content container
    });


    // canvi svg per png
    if (!feature.svg) {
        var imgs = document.getElementsByTagName('img');
        var endsWithDotSvg = /.*\.svg$/;
        var i = 0;
        var l = imgs.length;
        for (; i !== l; ++i) {
            if (imgs[i].src.match(endsWithDotSvg)) {
                imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
            }
        }
    }


    // smooth scroll top
    $(window).scroll(function() {
      if ($(this).scrollTop() > 400) {
          $(".top").fadeIn();
      } else {
          $(".top").fadeOut();
      }
    });
    var headerHeight = 82; // fixed header height
    $(function() {
      $('a[href*="#"]:not([href="#"],[href="#ir"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
        	  if (window.matchMedia("(min-width: 64.0625em)").matches) {

        	    	$('html, body').animate({
  		              scrollTop: target.offset().top - headerHeight
  		            }, 1000);
        	  } else {
		            $('html, body').animate({
		              scrollTop: target.offset().top
		            }, 1000);
        	  }
            return false;
          }
        }
      });
    });


	$(".avis a.close").on('click', function(){
		$(this).closest('.avis').addClass('fade-out');
		return false;
    });

});


;( function( $, window, document, undefined )
{
    'use strict';

    var $list       = $( '.fitxes-list' ),
        $items      = $list.find( '.fitxes-list__item' ),
        setHeights  = function()
        {
            $items.css( 'height', 'auto' );

            var perRow = Math.floor( $list.width() / $items.width() );
            if( perRow == null || perRow < 2 ) return true;

            for( var i = 0, j = $items.length; i < j; i += perRow )
            {
                var maxHeight   = 0,
                    $row        = $items.slice( i, i + perRow );

                $row.each( function()
                {
                    var itemHeight = parseInt( $( this ).outerHeight() );
                    if ( itemHeight > maxHeight ) maxHeight = itemHeight;
                });
                $row.css( 'height', maxHeight );
            }
        };

    setHeights();
    $( window ).on( 'resize', setHeights );

})( jQuery, window, document );

if ($('.doc-versions').length) {

	$('.doc-versions table').hide();

	$('.toggleTable').on('click', function(){
		$(this).toggleClass('opened');
		if ($(this).hasClass('opened')) {
			$(this).closest('.doc-versions').find('table').fadeIn();
			$('html, body').animate({
		        scrollTop: $(this).closest('.doc-versions').find('table').offset().top
		    }, 2000);
		} else {
			$(this).closest('.doc-versions').find('table').hide();
		}
	});
}

function flashMessengerAlert(errorType, message)
{
	var alert = '<div class="alert alert-block alert-' + errorType + ' flashMessenger fade in alert-dismissible" role="alert">'
		+ '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
		+ '<p>' + message + '</p>'
		+ '</div>';

	$('body').append(alert);
}

/*
$('.batchprocessing').on('click', function(e){
	e.preventDefault();
	flashMessengerAlert('success', $(this).data('alert-success-message'));
	$.ajax({
        type: 'GET',
        url: $(this).attr('href'),
        data: {},
        dataType: "json",
        async: true,
        success: function(data, textStatus, jqXHR) {
            if(data.isError == "true"){
            	flashMessengerAlert('danger', data.errorMessage);
            }
        }
    });
	return false;
});
*/

if ($('form.deleteItemsForm').length) {
	$('form.deleteItemsForm button[type="submit"]').prop('disabled', 'disabled');
	$('form.deleteItemsForm input.items').on('change', function(){
		if ($('form.deleteItemsForm input.items:checked').length) {
			$('form.deleteItemsForm button[type="submit"]').prop('disabled', false);
		} else {
			$('form.deleteItemsForm button[type="submit"]').prop('disabled', 'disabled');
		}
	});
}

$('.saveSearch').on('click', function(){
	$(this).toggleClass('open');
	$('#saveSearch').toggleClass('hidden');
});

//var exportSearch = false;
$('.exportSearch').on('click', function(e){

	e.preventDefault();
	$(this).toggleClass('open');
	$('#exportSearch').toggleClass('hidden');
//	if (!exportSearch) {
//		exportSearch = true;
//
//	    var data = {type: $(this).data('type')};
//	    $.ajax({
//	        type: 'GET',
//	        url: urlBase + '/validacions/ajax-get-export-fields.php',
//	        data: data,
//	        dataType: "json",
//	        async: false,
//	        success: function(data, textStatus, jqXHR) {
//	            if(data.isError == "true"){
//	            	errorMessage = data.errorMessage;
//	            	isError = true;
//	            } else {
//	            	$.each(data.items, function (i, item) {
//	            		$("#campsExport").append($('<option>', {
//	            	        value: item,
//	            	        text : item
//	            	    }));
//	            	});
//	            }
//	        }
//	    });
//	}

	return false;
});


if ($("#campsCheckboxExport").length > 0) {
	var urlAjaxCampsExport = urlBase + '/validacions/ajax-html-checkbox-export-fields.php?type=' + $('#campsCheckboxExport').data('type');

    $.ajax({
	    type: 'GET',
	    url: urlAjaxCampsExport,
	    dataType: "json",
	    success: function(data, textStatus, jqXHR) {
	        if(data.isError == "true"){
	        	errorMessage = data.errorMessage;
	        	isError = true;
	        	$("#campsCheckboxExport").html(erroMessage);
	        } else {
	        	$("#campsCheckboxExport").html(data.htmlContent);
	        }
	    }
	});
}


var $autocompletePoblacio = $('.form-control.codipostal-poblacio').autocomplete({
	source : []
});
function initOptionPoblacioInput(sourceValues) {

	$('.form-control.codipostal-poblacio').val('');
	$autocompletePoblacio.autocomplete('option', 'source', sourceValues);

	if (sourceValues.length == 1) {
		$('.form-control.codipostal-poblacio').val(sourceValues[0]);
	} else {
		$autocompletePoblacio.autocomplete('option', 'minLength', 0);
		$autocompletePoblacio.autocomplete('search', '');
	}
}

var cacheCP = {};
$('.form-control.codipostal').on('keyup', function(){
	var cp = $(this).val();
	if (cp.length == 5) {
		$(this).addClass('loading');

		var value = [];
		if (cp in cacheCP) {
			value = cacheCP[cp];
		} else {
			$.ajax({
			  dataType: "json",
			  url: urlBase + '/validacions/ajax-autocomplete-codipostal.php',
			  data: {term: cp},
			  async: false,
			  success: function(data, status, xhr) {
				  cacheCP[cp] = data;
			  }
			});
			if (cp in cacheCP) {
				value = cacheCP[cp];
			}
		}

		if (value.length == 1) {
			initOptionPoblacioInput(value[0].poblacions);
	    	$('.form-control.codipostal-provincia').val(value[0].provincia);
	    	$('.form-control.codipostal-ccaa').val(value[0].ccaa);
		}
		$(this).removeClass('loading');
	}
});

$(document).ready(function(){


    $(document).keydown(function(event){
        if(event.which=="17")
            cntrlIsPressed = true;
    });

    $(document).keyup(function(){
        cntrlIsPressed = false;
    });

    var cntrlIsPressed = false;

	$('table.hoverable tr').click( function() {
		if ($(this).find('a').length) {
			if (cntrlIsPressed) {
				window.open($(this).find('a').attr('href'));
			} else {
				window.location = $(this).find('a').attr('href');
			}
		}
	}).hover( function() {
		if ($(this).find('a').length) {
			$(this).toggleClass('hover-clickable');
		} else {
			$(this).toggleClass('hover');
		}
	});
	$('table.hoverable tr a').click( function(e) {
		e.preventDefault();
		$(this).closest('tr').trigger('click');
		return false;
	});

	/*
	 * Formulari d'ordenació, submit automàtic
	 */
	$("#ordenacio").on('change', function(){
		$(this).closest('form').submit();
	});


	/*
	 * Selectors Complexes de Dates
	 */
	$(".form-group.data-filter").each(function(){
		var container = $(this);


//		container.find('label').on('click', function(e){
//			container.find('.row-narrow').toggle();
//			$(this).toggleClass('open');
//			container.find('select.data-tipus').trigger('change');
//			return false;
//		});

		container.find('select.data-tipus').on('change', function(e){
			switch ($(this).val()) {
				case 'Igual':
				case 'Menor':
				case 'Major':
					container.find('.data-inicial').show().removeAttr('disabled');
					container.find('.data-final').hide().attr('disabled', 'disabled');
					break;
				case 'Entre':
					container.find('.data-inicial').show().removeAttr('disabled');
					container.find('.data-final').show().removeAttr('disabled');
					break;
				case 'Ple':
				case 'Buit':
				default:
					container.find('.data-inicial').hide().attr('disabled', 'disabled');
					container.find('.data-final').hide().attr('disabled', 'disabled');
					break;
			}
		});

		container.find('select.data-tipus').trigger('change');
//		if (container.find('select.data-tipus').val() == '') {
////			container.find('.row-narrow').hide();
//
//		} else {
//			container.find('select.data-tipus').trigger('change');
//		}

	});

	/*
	 * Selectors Complexes de Valors
	 */
	$(".form-group.valor-filter").each(function(){
		var container = $(this);

//
//		container.find('label').on('click', function(e){
//			container.find('.row-narrow').toggle();
//			$(this).toggleClass('open');
//			container.find('select.valor-tipus').trigger('change');
//			return false;
//		});

		container.find('select.valor-tipus').on('change', function(e){
			switch ($(this).val()) {
				case 'Igual':
				case 'Menor':
				case 'Major':
					container.find('.valor-inicial').show().removeAttr('disabled');
					container.find('.valor-inicial-addon').show();
					container.find('.valor-final').hide().attr('disabled', 'disabled');
					container.find('.valor-final-addon').hide();
					break;
				case 'Entre':
					container.find('.valor-inicial').show().removeAttr('disabled');
					container.find('.valor-inicial-addon').show();
					container.find('.valor-final').show().removeAttr('disabled');
					container.find('.valor-final-addon').show();
					break;
				case 'Ple':
				case 'Buit':
				default:
					container.find('.valor-inicial').hide().attr('disabled', 'disabled');
					container.find('.valor-inicial-addon').hide();
					container.find('.valor-final').hide().attr('disabled', 'disabled');
					container.find('.valor-final-addon').hide();
					break;
			}
		});

		container.find('select.valor-tipus').trigger('change');

//		if (container.find('select.valor-tipus').val() == '') {
//			container.find('.row-narrow').hide();
//		} else {
//			container.find('select.valor-tipus').trigger('change');
//		}


	});

	/*
	 * Pestanyes forms extensos
	 */
	$('.nav.tabs a').click(function (e) {
		  e.preventDefault()
		  $(this).tab('show')
	});

	/*
	 * Document viewport to fix footer
	 */

	function checkViewPortWindowHeight() {
		if ($(document).height() == $(window).height()) {
			$('body').addClass('fixed-footer');
		}
	}

	checkViewPortWindowHeight();

	$( window ).resize(checkViewPortWindowHeight);



	/*
	 * Automatismes que s'apliquen a la fitxa de cordons únicament si l'usuari disposa de permisos
	 */
	if (automatismesCordons) {
		$("#marcadorsserologics_mat_cmv").on('change', function() {
			$("#marcadorsserologics_cmv").val($(this).val());
		});

		$("#marcadorsserologics_mat_ebv").on('change', function() {
			$("#marcadorsserologics_ebv").val($(this).val());
		});

		$("#marcadorsserologics_mat_toxoplasmosis").on('change', function() {
			$("#marcadorsserologics_toxoplasmosis").val($(this).val());
		});

		$("#marcadorsserologics_mat_cmv_nat").on('change', function() {
			$("#marcadorsserologics_cmv_nat").val($(this).val());
		});

		$("#marcadorsserologics_mat_hbsag").on('change', function() {
			$("#marcadorsserologics_hbsag").val($(this).val());
		});

		$("#marcadorsserologics_mat_antihbc").on('change', function() {
			$("#marcadorsserologics_antihbc").val($(this).val());
		});

		$("#marcadorsserologics_mat_hbv_nat").on('change', function() {
			$("#marcadorsserologics_hbv_nat").val($(this).val());
		});

		$("#marcadorsserologics_mat_antihbs").on('change', function() {
			$("#marcadorsserologics_antihbs").val($(this).val());
		});

		$("#marcadorsserologics_mat_antihcv").on('change', function() {
			$("#marcadorsserologics_antihcv").val($(this).val());
		});

		$("#marcadorsserologics_mat_hcv_nat").on('change', function() {
			$("#marcadorsserologics_hcv_nat").val($(this).val());
		});

		$("#marcadorsserologics_mat_antihiv1_2").on('change', function() {
			$("#marcadorsserologics_antihiv1_2").val($(this).val());
		});

		$("#marcadorsserologics_mat_hiv_1_nat").on('change', function() {
			$("#marcadorsserologics_hiv_1_nat").val($(this).val());
		});

		$("#marcadorsserologics_mat_hiv_p24").on('change', function() {
			$("#marcadorsserologics_hiv_p24").val($(this).val());
		});

		$("#marcadorsserologics_mat_htlv_i_ii").on('change', function() {
			$("#marcadorsserologics_htlv_i_ii").val($(this).val());
		});

		$("#marcadorsserologics_mat_syphilis").on('change', function() {
			$("#marcadorsserologics_syphilis").val($(this).val());
		});

		$("#marcadorsserologics_mat_wnv").on('change', function() {
			$("#marcadorsserologics_wnv").val($(this).val());
		});

		$("#marcadorsserologics_mat_chagas").on('change', function() {
			$("#marcadorsserologics_chagas").val($(this).val());
		});

		$("#marcadorsserologics_mat_pb19_nat").on('change', function() {
			$("#marcadorsserologics_pb19_nat").val($(this).val());
		});

		$("#metodeprocesament_numerobosses").on('focusout', function(){
			if ($(this).val() == 1) {
				$("#idbossa").val($("#idbcu").val());
				$("#idmatern").val($("#idbcu").val() + 'M');
				$("#idmaternregistre").val($("#idbcu").val() + 'M');
			}
		});

	}


	var refreshTime = 60000; // every 1 minutes in milliseconds
    window.setInterval( function() {
        $.ajax({
            cache: false,
            type: "GET",
            url: "/media/php/sessionupdate.php",
            success: function(data) {
            }
        });
    }, refreshTime );


    /*
     * Informació alels del tipatge
     */
    $("input.validateTipatge").mouseenter(function (){
    	var inputField = $(this);
    	if(inputField.val() != '') {
        	var matches = inputField.attr('name').match(/tipatge\[(\w+)\]/);
        	if (matches) {
	        	var data = {variable : matches[1], valor : inputField.val()};
	            $.ajax({
	                type: 'GET',
	                mode: "abort",
	                url: urlBase + '/validacions/ajax-get-alels-tipatge.php',
	                data: data,
	                dataType: "json",
	                async: false,
	                success: function(data, textStatus, jqXHR) {
	                	if (data.title !== '' && data.content !== '') {
	                		inputField.attr('title', data.title);
	                		inputField.attr('data-placement', 'top');
	                		inputField.attr('data-content', data.content);
	                		inputField.popover({
	                			template: '<div class="popover tipatge-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><span class="close"><i class="fa fa-close fa-fw" aria-hidden="true"></i></span><div class="popover-content"></div></div>'
	                		});
	                		inputField.popover('show');
	
	                		inputField.keydown(function() {
	                			inputField.popover('destroy');
	                		});
	                	}
	                }
	            });
        	}
    	}

    	// amaguem automàticament la resta de popopvers
    	$("input.validateTipatge").each (function(){
    		if ($(this).attr('name') != inputField.attr('name')) {
    			$(this).popover('destroy');
    		}
    	});
    });
    // amaguem automàticament la resta de popopvers
    $("input.validateTipatge").focus(function (){
    	var focusedInput = $(this);
    	$("input.validateTipatge").each (function(){
    		if ($(this).attr('name') != focusedInput.attr('name')) {
    			$(this).popover('destroy');
    		}
    	});
	});

    $(document).on('click', '.tipatge-popover span.close', function(){
    	var inputField = $(this).closest('.form-group').find('input');
		inputField.popover('destroy');
    });

    $(window).click(function () {
        $(".popover.tipatge-popover").popover('destroy');
    });

    $(document).on('click', '.tipatge-popover', function(event){
    	event.stopPropagation();
    });

    /*
     * Informació alels del tipatge matern
     */
    $("input.validateTipatgeMatern").mouseenter(function (){
    	var inputField = $(this);
    	if(inputField.val() != '') {
        	var matches = inputField.attr('name').match(/marcadorsserologics\[(\w+)\]/);

        	var data = {variable : matches[1], valor : inputField.val()};
            $.ajax({
                type: 'GET',
                mode: "abort",
                url: urlBase + '/validacions/ajax-get-alels-tipatge.php',
                data: data,
                dataType: "json",
                async: false,
                success: function(data, textStatus, jqXHR) {
                	if (data.title !== '' && data.content !== '') {
                		inputField.attr('title', data.title);
                		inputField.attr('data-placement', 'top');
                		inputField.attr('data-content', data.content);
                		inputField.popover({
                			template: '<div class="popover tipatge-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><span class="close"><i class="fa fa-close fa-fw" aria-hidden="true"></i></span><div class="popover-content"></div></div>'
                		});
                		inputField.popover('show');

                		inputField.keydown(function() {
                			inputField.popover('destroy');
                		});
                	}
                }
            });
    	}

    	// amaguem automàticament la resta de popopvers
    	$("input.validateTipatgeMatern").each (function(){
    		if ($(this).attr('name') != inputField.attr('name')) {
    			$(this).popover('destroy');
    		}
    	});
    });
    // amaguem automàticament la resta de popopvers
    $("input.validateTipatgeMatern").focus(function (){
    	var focusedInput = $(this);
    	$("input.validateTipatgeMatern").each (function(){
    		if ($(this).attr('name') != focusedInput.attr('name')) {
    			$(this).popover('destroy');
    		}
    	});
	});


    $('.moreInfo').on('click', function(e){
    	e.preventDefault();
    	$(this).toggleClass('open');
    	$(this.hash).toggleClass('in');
    	return false;
    });


    $("a.confirmUrl").click(function(event) {
        event.preventDefault();
        var url = $(this).attr('href');
        var message = $(this).data('confirm-message');
        var btnYes = $(this).data('confirm-button-yes');
        var btnNo = $(this).data('confirm-button-no');
        vex.dialog.confirm({
            message: message,
            buttons: [
                $.extend({}, vex.dialog.buttons.YES, {
                    text: btnYes
                }), $.extend({}, vex.dialog.buttons.NO, {
                    text: btnNo
                })
            ],
            callback: function(value) {
                if (value) {
                    window.location = url
                }
            }
        });
        return false;
    });


    $("a.aside-nav-opener").click(function(event){
    	event.preventDefault();
    	$(".aside-nav").removeClass('aside-nav--hidden');
    	return false;
    });

    $(".aside-nav .close").click(function(event){
    	event.preventDefault();
    	$(".aside-nav").addClass('aside-nav--hidden');
    	return false;
    });
});
